import React from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Divider, IconButton, MenuItem, createStyles, withStyles, WithStyles} from "@material-ui/core";
import json2react from 'json2react';

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton, XIcon,
} from "react-share";
import PortalPopover from "./PortalPopover";
import PortalFontIcon from "./PortalFontIcon";
import {small} from "@stoplight/json/__tests__/fixtures/json";

const socialStyles = createStyles((theme: any) => ({
    socialSharing: theme.socialSharing,
    socialSharingButton: theme.socialSharingButton,
    socialSharingIcon: theme.socialSharingIcon,
    print: theme.print,
    clipboard: theme.clipboard,
    email: theme.email,
    divider: theme.divider,
    facebook: theme.facebook,
    twitter: theme.twitter,
    linkedin: theme.linkedin,
    reddit: theme.reddit,
    socialSharingItem: theme.socialSharingItem
}));

interface ISocialSharingProps extends WithStyles<typeof socialStyles> {
    buttons?: Array<string>;
    mainIcon?: React.ReactNode;
    url?: string;
    subject?: string;
    body?: string;
    classes: any;
    theme?: any;
    variant?: null | "facebook" | "linkedin" | "twitter" | "reddit" | "email" | "clipboard"
}

const SocialSharing = ({classes, theme, ...props}: ISocialSharingProps) => {
    if (props.variant) {
        const ShareComponent = props[props.variant] as any;
        return ShareComponent ? (
            <MenuItem key={props.variant} className={`${classes.socialSharingItem} ${classes[props.variant]} ${props.variant}`}>
                <ShareComponent {...props} classes={classes} theme={theme}/>
            </MenuItem>
        ) : null;
    }
    return (
        <PortalPopover TriggerComponent={IconButton}
                       triggerProps={{
                           className: classes.socialSharingButton,
                           role: "none",
                           title: "share"
                       }}
                       label={props.mainIcon}>
            <div role={"menu"} className={classes.socialSharing}>
                {props.buttons.map((b) => {
                    const ShareComponent = props[b] as any;
                    const componentProps = ShareComponent?.props || {};
                    return typeof(ShareComponent) !== "object" ? (
                        <MenuItem key={b} className={`${classes.socialSharingItem} ${classes[b]} ${b}`}>
                            <ShareComponent {...props} {...componentProps} classes={classes} theme={theme}/>
                        </MenuItem>
                    ) : json2react.bind(null, React.createElement, ShareComponent)();
                })}
                <Divider className={classes.divider}/>
            </div>
        </PortalPopover>
    );
};
const SocialFontIcon = ({className, ...props}) => <PortalFontIcon {...props}
                                                                  className={`${props.classes.socialSharingIcon} social-icons ${className || props.name}`}/>

SocialSharing.defaultProps = {
    buttons: [
        "facebook",
        "linkedin",
        "twitter",
        "reddit",
        "email",
        "clipboard",
    ],
    mainIcon: (<PortalFontIcon name="share"/>),
    iconProps: {
        classes: {},
        round: 'true',
        size: 32
    },
    print: () => (<PortalFontIcon name="print" fontSize="large"/>),
    email: (props) => (
        <EmailShareButton url={props.url}
                          subject={props.subject}
                          body={`${props.body}\n`}>
            <PortalFontIcon {...props.iconProps} className={`${props.classes.socialSharingIcon} email`}
                            name="mail_outline"/>
        </EmailShareButton>),
    clipboard: (props) =>
        (<CopyToClipboard text={props.url}>
            <PortalFontIcon className={`${props.classes.socialSharingIcon} link`} name="link"/>
        </CopyToClipboard>),
    facebook: (props) =>
        (<FacebookShareButton url={props.url}
                              hashtag={props.subject}>
            <SocialFontIcon {...props.iconProps} classes={props.classes} name="facebook"/>
        </FacebookShareButton>),
    linkedin: (props) =>
        (<LinkedinShareButton url={props.url}
                              title={props.subject}
                              summary={props.body}
                              source={window.location.hostname}>
            <SocialFontIcon {...props.iconProps} classes={props.classes} className="linkedin" name="in"/>
        </LinkedinShareButton>),
    twitter: (props) =>
        (<TwitterShareButton url={props.url} title={props.subject} via={window.location.hostname}>
            <XIcon {...props.iconProps} classes={props.classes} name="twitter" size={24}/>
        </TwitterShareButton>),
    reddit: (props) =>
        (<RedditShareButton url={props.url} title={props.subject}>
            <SocialFontIcon {...props.iconProps} classes={props.classes} className="reddit" name="x"/>
        </RedditShareButton>),
}
export default withStyles(socialStyles, {name: "SocialSharing"})(SocialSharing)
